import React, {Component} from 'react';
import {withRouter} from 'react-router';

class Footer extends Component {
  render() {
    return(
      <div id="footer">
        <nav class="navFooter">
          <div class="navFooter__apps">
            <a href="https://itunes.apple.com/us/app/donatos/id599722601?mt=8" target="_blank">
              <img class="download-app-icon" src="/imgs/footer/download-app-store.png" alt="Download on the App Store"/>
            </a>
            <a class="right" href="https://play.google.com/store/apps/details?id=com.donatos.phoenix&amp;hl=en" target="_blank">
              <img class="download-app-icon" src="/imgs/footer/download-google-play.png" alt="Get it on Google Play"/>
            </a>
          </div>

          <p class="navFooter__copy">
            © 2020 Donatos. All Rights Reserved. <a href="https://www.donatos.com/termsandconditions">Terms &amp; Conditions</a> | <a href="https://www.donatos.com/privacy-policy">Privacy Policy</a>
          </p>

          <div class="navFooter__social">
            <a href="//www.yelp.com/biz/donatos-pizza-mansfield" target="_blank" class="icon--block icon--yelp" rel="publisher">Yelp</a>
            <a href="https://www.facebook.com/DonatosPizza" target="_blank" class="icon--block icon--facebook">Facebook</a>
            <a href="https://instagram.com/donatospizza" target="_blank" class="icon--block icon--instagram">Instagram</a>
            <a href="https://www.twitter.com/Donatos" target="_blank" class="icon--block icon--twitter">Twitter</a>
          </div>
        </nav>
      </div>
    )
  };
};

export default withRouter(Footer);
