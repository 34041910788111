import React, { Component } from 'react';
import {Route, Switch} from "react-router-dom";
import SideBarNavigation from './SideBarNavigation';
import HeaderMenu from './HeaderMenu';
import Footer from './Footer';
import {withRouter} from "react-router";
import GiftCardController from './Controllers/GiftCardController';
import {SolsysAuthReact} from 'solsys-auth-react';
import 'semantic-ui-css/semantic.min.css';
import './App.css';


class App extends Component {

  static defaultState = {
    isNavMenuVisible: false,
  };

  state = App.defaultState;

  openNavMenu = () => {
    document.getElementsByTagName("body")[0].style = "overflow: hidden;"
    this.setState({isNavMenuVisible: true})
  }

  closeNavMenu = () => {
    document.getElementsByTagName("body")[0].style = ""
    this.setState({isNavMenuVisible: false})
  }

  render = () => {
    return (
      <SolsysAuthReact clientOnly>
        <div className='app'>
          <SideBarNavigation
            isNavMenuVisible={this.state.isNavMenuVisible}
            openNavMenu={this.openNavMenu}
            closeNavMenu={this.closeNavMenu}
          >
            <HeaderMenu
              openNavMenu={this.openNavMenu}
            />

            <div className='appBody'>
              <img id="desktopHeaderImg" src="/imgs/giftcard-banner-withtext-2x.png" alt="Giftcard Banner"/>
              <img id="mobileHeaderImg" src="/imgs/header-mobile@2x.png" alt="Giftcard Banner"/>
              <Switch>
                <Route
                  path='/'
                  exact
                  render={(props) => {
                    props.history.replace('/gift-card')
                  }}
                />
                <Route
                  path='/gift-card'
                  render={(props) => {
                    return (
                      <GiftCardController/>
                    )
                  }}
                />
              </Switch>
            </div>

            <Footer/>
          </SideBarNavigation>
        </div>
      </SolsysAuthReact>
    )
  }
}

export default withRouter(App);
